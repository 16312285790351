import React from 'react'
import { PropTypes } from 'prop-types'
import { Box, Typography, withTheme } from '@material-ui/core'
import Img from 'gatsby-image'
import Triangle from './graphics/Triangle'
import Circle from './graphics/Circle'

const FullBleedBanner = ({ theme, title, overlayImage }) => (
  <Box
    height={{ xs: 186, md: 378 }}
    display="flex"
    justifyContent="center"
    alignItems="center"
    position="relative"
    overflow="hidden"
  >
    <Box zIndex="1">
      <Typography variant="h1">{title}</Typography>
    </Box>
    <Box
      position="absolute"
      width={{ xs: 144.5, md: 289 }}
      top={{ xs: '-2em', md: '-4em' }}
      left={{ xs: '-4em', md: '-7em' }}
      style={{ opacity: 0.5 }}
      zIndex="0"
    >
      <Triangle color={theme.palette.primary.main} />
    </Box>
    <Box
      width={{ xs: 266, md: 532 }}
      position="absolute"
      height={{ xs: 266, md: 532 }}
      bottom={{ xs: '-9em', md: '-19em' }}
      right={{ xs: '-9em', md: '-20em' }}
      style={{ opacity: 0.3 }}
      zIndex="0"
    >
      <Circle color={theme.palette.primary.secondary} />
    </Box>
    {overlayImage ? (
      <Box width="100%" position="absolute" bottom="0" left="0" zIndex="1">
        <Img fluid={overlayImage.childImageSharp.fluid} />
      </Box>
    ) : null}
  </Box>
)

FullBleedBanner.propTypes = {
  title: PropTypes.string.isRequired,
  overlayImage: PropTypes.shape({
    childImageSharp: PropTypes.shape({ fluid: PropTypes.shape({}) }),
  }),
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string.isRequired,
        secondary: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
}

FullBleedBanner.defaultProps = {
  overlayImage: null,
}

export default withTheme(FullBleedBanner)
