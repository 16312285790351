const parseUrl = (url) => {
  if (!url) return ''

  let parsedUrl = url
  const addHttps = (u) => `https://${u}`

  const startsWithWWW = parsedUrl.startsWith('www.')

  if (startsWithWWW) {
    parsedUrl = addHttps(url)
  }

  const startsWithHttp = parsedUrl.startsWith('https://') || parsedUrl.startsWith('http://')

  if (!startsWithHttp) {
    parsedUrl = addHttps(url)
  }

  return parsedUrl
}

export default parseUrl
