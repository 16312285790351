import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import {
  Container, Box, Typography, Link, makeStyles,
} from '@material-ui/core'
import Layout from '../../components/Layout'
import ComponentsList from '../../components/components-list/List'
import FullBleedBanner from '../../components/FullBleedBanner'
import parseUrl from '../../utils/parseUrl'
import AboutBlock from '../../components/AboutBlock'
import NewsletterBlock from '../../components/NewsletterBlock'
import { formatFileSrc } from '../../utils/cms'
import trackLinkClick from '../../utils/trackLinkClick'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.contrastTertiary,
  },
  bodyWrapper: {
    color: theme.palette.primary.dark,
    background: theme.palette.primary.light,
  },
}))

export const PrivacyTemplate = ({
  overlayImage,
  title,
  additionalLinks,
  components,
  pdf,
}) => {
  const classes = useStyles()
  useEffect(() => {
    window.dataLayer.push({ page: null })
    window.dataLayer.push({
      event: 'page_view',
      page: {
        '@type': 'WebPage',
        breadcrumb: 'Home > Privacy',
        id: 'privacy',
        name: 'Privacy Page',
        page_category: 'WebPage',
      },
    })
  }, [])

  return (
    <Box mb={{ xs: 3, md: 6 }}>
      <FullBleedBanner title="Privacy at TriNet" overlayImage={overlayImage} />
      <Box className={classes.bodyWrapper} pt={{ xs: 10, md: 16 }} pb={{ xs: 16, md: 24 }}>
        <Container maxWidth="md">
          <Box>
            {additionalLinks.map(
              ({ title: additionalLinkTitle, titleUrl, description }) => (
                <Box mb={2}>
                  <Link
                    key={titleUrl}
                    href={parseUrl(titleUrl)}
                    target="_blank"
                    className={classes.link}
                    onClick={trackLinkClick}
                  >
                    {additionalLinkTitle}
                  </Link>
                  <Typography variant="body1">{description}</Typography>
                </Box>
              ),
            )}
          </Box>
        </Container>
        <Box textAlign="center" mt={{ xs: 8.75, md: 17.5 }}>
          <Typography variant="h1">{title}</Typography>
        </Box>
        <ComponentsList components={components} />
        <Container maxWidth="md">
          <Box>
            {pdf.map(({ label, file }) => (
              <Link
                key={file.name}
                href={formatFileSrc(file)}
                download={file.name}
                className={classes.link}
                onClick={trackLinkClick}
              >
                {label}
              </Link>
            ))}
          </Box>
        </Container>

      </Box>
    </Box>
  )
}

PrivacyTemplate.propTypes = {
  overlayImage: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  components: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  additionalLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      titleUrl: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  pdf: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.shape({
        publicURL: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ).isRequired,
}

PrivacyTemplate.defaultProps = {
  overlayImage: null,
}

const Privacy = ({
  data: {
    overlayImage,
    pageData: {
      frontmatter: {
        title, additionalLinks, components, pdf,
      },
    },
  },
}) => (
  <Layout>
    <PrivacyTemplate
      overlayImage={overlayImage}
      title={title}
      additionalLinks={additionalLinks}
      components={components}
      pdf={pdf}
    />
    <AboutBlock />
    <NewsletterBlock />
  </Layout>
)

Privacy.propTypes = {
  data: PropTypes.shape({
    overlayImage: PropTypes.shape({}),
    pageData: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        components: PropTypes.arrayOf(PropTypes.shape({})),
        additionalLinks: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            titleUrl: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
          }),
        ),
        pdf: PropTypes.arrayOf(
          PropTypes.shape({
            file: PropTypes.shape({
              publicURL: PropTypes.string,
              name: PropTypes.string,
            }),
          }),
        ),
      }),
    }),
  }).isRequired,
}

export const pagesQuery = graphql`
  query privacyPage {
    overlayImage: file(relativePath: { eq: "overlayhorizontalstatic.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pageData: markdownRemark(fields: {slug: {eq: "/pages/privacy/"}}) {
      frontmatter {
        title
        additionalLinks {
          title
          titleUrl
          description
        }
        pdf {
          file {
            name
            publicURL
          }
          label
        }
        ...Title
        ...Text
        ...Quote
        ...AudioText
        ...ImageText
        ...Bullets
        ...FullWidthImage
      }
    }
  }
`

export default Privacy
